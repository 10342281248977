<template>
  <v-container>
    <mode-of-recovery-list/>
  </v-container>
</template>

<script>
import ModeOfRecoveryList from "@/components/app_setting/mode_of_recovery/ModeOfRecoveryList";

export default {
  name: "ModeOfRecoveryPage",
  components: {
    ModeOfRecoveryList,
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Mode of Recovery'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>